import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FeedbackService } from '../feedback.service';
import { log } from 'console';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate {
  private isUserAuthenticated: boolean = false;
  private credentials = {
    email: 'dan@supramagic.com',
    password: 'Yoxiapp22'
  };

  constructor(
    private router: Router,
    private feebackServ: FeedbackService,
    private readonly http: HttpClient,
    private _snackBar: MatSnackBar,
  ) {
    const activeSession = JSON.parse(window.sessionStorage.getItem('activeSession'))
    if(activeSession) {
      console.log('activeSession => ', activeSession)
      this.isUserAuthenticated = true;
    } else {
      console.log('activeSession => ', activeSession)
      this.isUserAuthenticated = false;
    }
  }

  showSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
        duration: 4500,
        panelClass: 'login-alert-error'
    });
    
}

  async validateUser(email:  string, password: string) {
    console.log(email, password);
    try {
      const isUserAuthenticated = await this.feebackServ.logUser(email, password).then(async res => {
        console.log('res => ', res)
        if(res.body && res.status === 200) {
          //console.log("Login::::>", res.body)
          window.sessionStorage.setItem('activeSession', JSON.stringify(true));
          window.sessionStorage.setItem('user', JSON.stringify(res.body));
          localStorage.setItem('userRole',JSON.stringify(res.body.role_name));
          console.log('rol =>', res.body.role_name );
          
          this.feebackServ.toggleUserRoleName(res.body.role_name);
          this.isUserAuthenticated = true;
        } else if(res.message && res.status !== 200) {
          window.sessionStorage.setItem('activeSession', JSON.stringify(false));
          this.isUserAuthenticated = false;
          /* this.feebackServ.presentAlertBasic('Error', res.message, 'btn-error'); */         
          this.showSnackBar('Wrong email or password. Try again', 'Dismiss')
        }
        console.log('Login - isUserAuthenticated => ', this.isUserAuthenticated)
        return this.isUserAuthenticated;
      })
      return isUserAuthenticated
    } catch (error) {
      /* this.feebackServ.presentAlertBasic('Error', 'Something went wrong', 'btn-error'); */
      this.showSnackBar('Something went wrong. Try again', 'Dismiss')
      return false
    }
  }

  logOutUser() {
    window.sessionStorage.clear()
    window.localStorage.clear()
    this.isUserAuthenticated = false;
    this.router.navigate(['/login'], { replaceUrl: true });
    console.log('Logout - isUserAuthenticated => ', this.isUserAuthenticated)
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    console.log('isUserAuthenticated => ', this.isUserAuthenticated)
    if(!this.isUserAuthenticated) {
      this.router.navigate(['login'])
      return false;
    } else {
      return true;
    }
  }
  
}
