import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthGuard } from 'src/services/guardAuth/guard-auth.guard';
import { FeedbackService } from 'src/services/feedback.service';



@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  userRole: string;
  public adminRole: string = "ADMIN"; 

  constructor(
    private router: Router,
    private authService: AuthGuard,
    private feebackServ: FeedbackService    
  ) {
    this.userRole = JSON.parse(window.sessionStorage.getItem('user'))?.role_name;
    this.feebackServ.userRoleNameChange.subscribe(value => {
      this.userRole = value;
    });
  }
 
  setView(url) {
    this.router.navigateByUrl(url);
  }

  logOut() {
    this.authService.logOutUser()
  }

  cancelAccount() {
    this.router.navigate(['/cancel-account']);
  }
}
