export interface AlertInterface {
  header: string;
  subHeader: string;
  mode: any;
  backdropDismiss: boolean;
  message: string;
  animated: boolean;
  buttons: AlertButtonInterface[];
}

export interface AlertButtonInterface {
  text: string;
  role?: 'cancel' | 'destructive' | string;
  cssClass?: string | string[];
  handler?: (value: any) => boolean | void ;
}


export class AlertModel implements AlertInterface {
  header: string;
  subHeader: string;
  mode: any;
  backdropDismiss: boolean;
  message: string;
  animated: boolean;
  buttons: AlertButtonInterface[];
  constructor(header: string, message: string, buttons: AlertButtonInterface[]) {
    this.header = header;
    this.subHeader = '';
    this.mode = 'ios';
    this.backdropDismiss = false;
    this.message = message;
    this.animated = true;
    this.buttons = buttons;
  }
}

export class AlertButtonModel implements AlertButtonInterface {
  text: string;
  role?: 'cancel' | 'destructive' | string;
  cssClass?: string | string[];
  handler?: (value: any) => boolean | void;
  constructor(
    text: string,
    role: 'cancel' | 'destructive' | string,
    cssClass?: string | string[],
    handler?: (value: any) => boolean | void
  ) {
    this.text = text;
    this.role = role;
    this.cssClass = cssClass;
    this.handler = handler;
  }
}