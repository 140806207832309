import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/services/guardAuth/guard-auth.guard';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'welcome',
    loadChildren: () => import('./welcome/welcome.module').then( m => m.WelcomePageModule)
  },
  {
    path: 'students-feedback',
    loadChildren: () => import('./students-feedback/students-feedback.module').then( m => m.StudentsFeedbackPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'teacher-form',
    loadChildren: () => import('./teacher-form/teacher-form.module').then( m => m.TeacherFormPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'student-poll/:dynamic_id',
    loadChildren: () => import('./student-poll/student-poll.module').then( m => m.StudentPollPageModule)
  },
  {
    path: 'student-form',
    loadChildren: () => import('./student-form/student-form.module').then( m => m.StudentFormPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'thank-student',
    loadChildren: () => import('./thank-student/thank-student.module').then( m => m.ThankStudentPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login-teacher/login-teacher.module').then( m => m.LoginTeacherPageModule)
  },
  {
    path: 'teacher-options',
    loadChildren: () => import('./teacher-options/teacher-options.module').then( m => m.TeacherOptionsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'teacher-qr-poll',
    loadChildren: () => import('./teacher-qr-poll/teacher-qr-poll.module').then( m => m.TeacherQrPollPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'vote-preview',
    loadChildren: () => import('./vote-preview/vote-preview.module').then( m => m.VotePreviewPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'vote-list',
    loadChildren: () => import('./vote-list/vote-list.module').then( m => m.VoteListPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'share',
    loadChildren: () => import('./share/share.module').then( m => m.SharePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'thanks',
    loadChildren: () => import('./thanks/thanks.module').then( m => m.ThanksPageModule)
  },
  {
    path: 'teacher-form-groups',
    loadChildren: () => import('./teacher-form-groups/teacher-form-groups.module').then( m => m.TeacherFormGroupsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'voting-process',
    loadChildren: () => import('./voting-process/voting-process.module').then( m => m.VotingProcessPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'vote-review',
    loadChildren: () => import('./vote-review/vote-review.module').then( m => m.VoteReviewPageModule),
  },
  {
    path: 'dynamic-list',
    loadChildren: () => import('./dynamic-list/dynamic-list.module').then( m => m.DynamicListPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'students-list',
    loadChildren: () => import('./student-list/student-list.module').then( m => m.StudentListPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'dynamic-create',
    loadChildren: () => import('./dynamic-create/dynamic-create.module').then( m => m.DynamicCreatePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'onboarding',
    loadChildren: () => import('./onboarding/onboarding.module').then( m => m.OnboardingPageModule),
  },
  {
    path: 'users-list',
    loadChildren: () => import('./users-list/users-list.module').then( m => m.UsersListPageModule)
  },
  {
    path: 'create-user-form',
    loadChildren: () => import('./create-user-form/create-user-form.module').then( m => m.CreateUserFormPageModule)
  },
  {
    path: 'user-details',
    loadChildren: () => import('./user-details/user-details.module').then( m => m.UserDetailsPageModule)
  },
  {
    path: 'teams-list',
    loadChildren: () => import('./teams-list/teams-list.module').then( m => m.TeamsListPageModule)
  },
  {
    path: 'create-team-form',
    loadChildren: () => import('./create-team-form/create-team-form.module').then( m => m.CreateTeamFormPageModule)
  },
  {
    path: 'team-details',
    loadChildren: () => import('./team-details/team-details.module').then( m => m.TeamDetailsPageModule)
  },
  {
    path: 'add-team-member',
    loadChildren: () => import('./add-team-member/add-team-member.module').then( m => m.AddTeamMemberPageModule)
  },
  {
    path: 'register-user-form',
    loadChildren: () => import('./register-user-form/register-user-form.module').then( m => m.RegisterUserFormPageModule)
  },
  {
    path: 'register-workspace-form',
    loadChildren: () => import('./register-workspace-form/register-workspace-form.module').then( m => m.RegisterWorkspaceFormPageModule)
  },
  {
    path: 'register-email-form',
    loadChildren: () => import('./register-email-form/register-email-form.module').then( m => m.RegisterEmailFormPageModule)
  },
  {
    path: 'register-password-form',
    loadChildren: () => import('./register-password-form/register-password-form.module').then( m => m.RegisterPasswordFormPageModule)
  },
  {
    path: 'register-terms-conditions-form',
    loadChildren: () => import('./register-terms-conditions-form/register-terms-conditions-form.module').then( m => m.RegisterTermsConditionsFormPageModule)
  },
  { path: '**', redirectTo: '/login' },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
