import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { poll } from "src/interfaces/poll.interface";
import { dynamic, dynamicModel } from "src/interfaces/dynamic.interface";
import { AlertController, LoadingController } from "@ionic/angular";
import {
    addDoc,
    collection,
    collectionData,
    deleteDoc,
    doc,
    Firestore,
    getDoc,
    setDoc,
    updateDoc,
} from "@angular/fire/firestore";
import { Subject } from "rxjs";
import {
    AlertButtonInterface,
    AlertButtonModel,
    AlertInterface,
    AlertModel,
} from "src/interfaces/alert.interface";
import generatePassword from "omgopass";
import { log } from "console";
import { UserRoles } from "src/common/constants";

@Injectable({
    providedIn: "root",
})
export class FeedbackService {
    private newPoll: poll;
    private dynamic: dynamic;

    selectedUserId: number;
    selectedTeamId: number;
    selectedUserPassword: string;

    workspaceId: number;

    public userRoleName: string;
    public userRoleNameChange: Subject<string> = new Subject<string>();

    private loading;

    private endpointPolls =
        "https://35gxeernz7ffrtul5s6zv6xnvm0mkghe.lambda-url.us-west-2.on.aws/";
    private endpointVotes =
        "https://ao2inudl5cxxoecqrrygn35ega0gpbkp.lambda-url.us-west-2.on.aws/";
    private endpointDynamics =
        "https://olwfma5v77xfddshjqxbhet6da0cercc.lambda-url.us-west-2.on.aws/";
    private endpointLogin =
        "https://zz5bzklfldloz3wkrn3afpq4yy0eiwro.lambda-url.us-west-2.on.aws/";
    private endpointCatalogs =
        "https://pezyhygu4itaelabmrshgyqiuq0cgxcc.lambda-url.us-west-2.on.aws/";
    private endpointAlumnos = 
        "https://2o6znmb5tqezjupmy4izyb5oea0pmipv.lambda-url.us-west-2.on.aws/"

    get getNewPoll(): poll {
        return JSON.parse(window.localStorage.getItem("newPoll"));
    }

    set setNewPoll(poll: poll) {
        window.localStorage.setItem("newPoll", JSON.stringify(poll));
    }

    set setNewPollGroups(groups) {
        this.newPoll.groups = groups;
    }

    get getDynamic(): dynamic {
        return JSON.parse(window.localStorage.getItem("dynamicSelected"));
    }

    set setDynamic(dynamic: dynamic) {
        window.localStorage.setItem("dynamicSelected", JSON.stringify(dynamic));
    }

    constructor(
        private loadingCtrl: LoadingController,
        private alertController: AlertController,
        private firestore: Firestore,
        private readonly http: HttpClient
    ) {}

    toggleUserRoleName(userRoleName) {
        this.userRoleNameChange.next(userRoleName);
    }

    getActivePoll() {
        return parseInt(window.localStorage.getItem("activePoll"));
    }

    setActivePoll(data: string) {
        return window.localStorage.setItem("activePoll", data);
    }

    setRemovePoll() {
        window.localStorage.removeItem("newPoll");
    }

    setDiscardGroups(groups){
        groups = [{ group_id: null, title: "" }, { group_id: null, title: "" }];
    }

    setSelectedUser(userId: number) {
       this.selectedUserId = userId
    }

    setSelectedTeam(teamId:number){
        this.selectedTeamId = teamId;
    }

    setSelectedUserPassword(password: string) {
        this.selectedUserPassword = password
     }

     setCurrentWorkspace(workspace: number){
        this.workspaceId = workspace
     }

    async showLoading() {
        this.loading = await this.loadingCtrl.create({
            mode: "ios",
        });
        await this.loading.present();
    }

    
    generateRandomString() {
        const newPass = generatePassword();
        return newPass;
    }

    async hideLoading() {
        await this.loading.dismiss();
    }

    // async addDynamic(dynamic: dynamic){
    //   const dynamicRef = collection(this.firestore, 'dynamics');
    //   return (await addDoc(dynamicRef, dynamic)).id;
    // }

    // getDynamics():Observable<dynamic[]>{
    //   const dynamicRef = collection(this.firestore, 'dynamics');
    //   return collectionData(dynamicRef, { idField: 'id' }) as Observable<dynamic[]>;
    // }

    async deteleDynamic(dynamic: dynamic) {
        const dynamicRef = doc(this.firestore, `dynamics/${dynamic.id}`);
        return await deleteDoc(dynamicRef);
    }

    // async getDynamicById(dynamicId) {
    //   console.log(dynamicId);
    //   const dynamicRef = doc(this.firestore, 'dynamics', `${dynamicId}`);

    //   try {
    //     const docSnap = await getDoc(dynamicRef);
    //     if (docSnap.exists()) {
    //       console.log(docSnap.id);
    //       let dynamic = docSnap.data();
    //       dynamic.id = docSnap.id
    //       return dynamic;
    //     } else {
    //       return null;
    //     }
    //   } catch (error) {
    //     console.log(error)
    //   }
    // }

    // async updateDynamic(dynamic) {
    //   const dynamicRef = doc(this.firestore, `dynamics/${dynamic.id}`);
    //   return await updateDoc(dynamicRef, dynamic);
    // }

    async presentAlert(header: string, message: string, styleBotton: string = null) {
        let buttons: AlertButtonInterface[] = styleBotton
        ? [
            new AlertButtonModel("Cancel", "cancel", styleBotton),
            new AlertButtonModel("Accept", "agree", styleBotton),
        ] 
        : [
            new AlertButtonModel("Cancel", "cancel"),
            new AlertButtonModel("Accept", "agree"),
        ];
        const alert = await this.alertController.create(
            new AlertModel(header, message, buttons)
        );
        await alert.present();
        const { role } = await alert.onWillDismiss();
        return role;
    }

    async presentAlertBasic(header: string, message: string,
        styleBotton: string = null
    ) {
        let buttons: AlertButtonInterface[] = styleBotton
            ? [new AlertButtonModel("ACCEPT", "agree", styleBotton)]
            : [new AlertButtonModel("ACCEPT", "agree")];
        const alert = await this.alertController.create(
            new AlertModel(header, message, buttons)
        );
        await alert.present();
    }

    /**
     * @description Use method get everywhere to execute anyone queries
     **/
    private async executeMethodGetQuery<T>(endpoint: string, parameter?: any) {
        const url = `${endpoint}${parameter}`;
        return await this.http.get<any>(url).toPromise();
    }
    /**
     * @description Use method post everywhere to execute anyone queries
     **/
    private async executeMethodPostQuery<T>(endpoint: string, parameter?: any) {
        const url = endpoint;
        return await this.http.post<any>(url, parameter).toPromise();
    }

      /**
     * @description Use method put everywhere to execute any JSON in body
     **/
      private async executeMethodPutBody<T>(endpoint: string, body?: Object) {
        const url = endpoint;
        const headers = new HttpHeaders();

        headers.append('Content-Type', 'text/html; charset=UTF-8')
        
        return await this.http.put<any>(url, body, { headers }).toPromise();
    }

    /**
     * @description Use method post everywhere to execute any JSON in body
     **/
    private async executeMethodPostBody<T>(endpoint: string, body?: Object) {
        const url = endpoint;
        const headers = new HttpHeaders();
        // Eliminar el encabezado Content-Type de la solicitud
        // Esto puede variar según la versión de Angular que estés utilizando
       /*  headers.delete('Content-Type');
        headers.set('Access-Control-Allow', '*'); */

        headers.append('Content-Type', 'text/html; charset=UTF-8')
        
        return await this.http.post<any>(url, body, { headers }).toPromise();
        /* return await this.http.post<any>(url, body).toPromise(); */
    }
    /**
     * @description Use method delete everywhere to execute anyone queries
     **/
    private async executeMethodDeleteQuery<T>(
        endpoint: string,
        parameter?: any
    ) {
        const url = `${endpoint}${parameter}`;
        return await this.http.delete<any>(url).toPromise();
    }

    //Prueba eliminar user

    private async executeMethodDeleteItem<T>(endpoint: string,body?: Object) {       
        const options = {
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body) // Set payload to string
        };
    
        return await this.http.delete<any>(endpoint, options).toPromise();       
    }


    /**
     * @description Use method put everywhere to execute anyone queries
     **/
    private async executeMethodPuttQuery<T>(endpoint: string, parameter?: any) {
        const url = endpoint;
        return await this.http.put<any>(url, parameter).toPromise();
    }

    async logUser(email, password, isReactivation: boolean = false) {
        const body = {
            email,
            password,
            isReactivation
        };
        const response = await this.executeMethodPostQuery(
            this.endpointLogin,
            body
        );
        return response;
    }

    async getDynamics(offset, keyword, filters) {
        let filterKeyword = "";
        let filtersTopics: string = "";
        if (keyword) {
            filterKeyword = `&keyword=${keyword}`;
        }
        if (filters) {
            filtersTopics += filters.privacy
                ? `&privacy=${filters.privacy}`
                : "";
            filtersTopics += filters.subjectId
                ? `&subjectId=${filters.subjectId}`
                : "";
            /* filtersTopics += filters.gradeId ? `&gradeId=${filters.gradeId}` : ""; */
            filtersTopics += filters.groupId ? `&groupId=${filters.groupId}` : "";
            filtersTopics += filters.teacherId ? `&teacherId=${filters.teacherId}` : "";
        }
        const response = await this.executeMethodGetQuery(
            this.endpointDynamics,
            `?offset=${offset}${filterKeyword}${filtersTopics}`
        );

        return response.body;
    }

    async getUsersByRole(offset, keyword, workspaceId, requestUserId = 0){
        const queryParams = (`usersListByRole?offset=${offset ? offset : 0}${keyword ? `&keyword=${keyword}` : ""}${workspaceId ? `&workspaceId=${workspaceId}` : ""}&requestUserId=${requestUserId}`)
        const response = await this.executeMethodGetQuery(
            this.endpointAlumnos,
            queryParams
        );
        return response.body;
    }

    async getUsers(offset, keyword, workspaceId) {
        const queryParams = (`usersList?offset=${offset ? offset : 0}${keyword ? `&keyword=${keyword}` : ""}${workspaceId ? `&workspaceId=${workspaceId}` : ""}`)
        const response = await this.executeMethodGetQuery(
            this.endpointAlumnos,
            queryParams
        );
        return response.body;
    }

    async getStudents(keyword, workspaceId: number, roleId: number, userId: number) {
        userId = (roleId == UserRoles.ADMIN) ? 0 : userId; 
        const queryParams = (`studentsList?${keyword ? `keyword=${keyword}` : ""}${workspaceId ? `&workspaceId=${workspaceId}` : ""}&requestUserId=${userId}`)
        const response = await this.executeMethodGetQuery(
            this.endpointAlumnos,
            queryParams
        );
        return response.body;
    }

    async validateEmail(user) {        
        const response = await this.executeMethodPostBody(
            this.endpointDynamics + 'validateEmail', user            
        );
        return response;
    } 

    async validateEmailNewRegitry(email) {    
        const bodyObject = {"email": email};    
        const response = await this.executeMethodPostBody(
            this.endpointDynamics + 'validateEmailNewRegitry', bodyObject            
        );
        return response;
    } 

    async createUser(user) {   
        const response = await this.executeMethodPostBody(
            this.endpointDynamics + 'newUser', user            
        );
        return response;
    } 

    async newUserPassword(user) {        
        const response = await this.executeMethodPutBody(
            this.endpointDynamics + 'newUserPassword', user            
        );
        return response;
    } 
    
    async getUserById(selectedUserId) {
        
        const queryParams = (`usersList?&userId=${selectedUserId}`)
        const response = await this.executeMethodGetQuery(
            this.endpointAlumnos,
            queryParams
        );
        return response.body;
    }

    /* async logUser(email, password) {
        const body = {
            email,
            password,
        };
        const response = await this.executeMethodPostQuery(
            this.endpointLogin,
            body
        );
        return response;
    } */

    async deleteUser(user) {
        const response = await this.executeMethodDeleteItem(
            this.endpointDynamics + 'deleteUser', user          
        );
        return response;
    }

    async getTeamUsers(teamId){       
        const queryParams = (`groupStudents?&classGroupId=${teamId}`)
        const response =  await this.executeMethodGetQuery(
            this.endpointCatalogs, queryParams);
        return response     
    }

    async createTeam(team) {        
        const response = await this.executeMethodPostBody(
            this.endpointDynamics + 'newGroup', team            
        );
        return response;
    } 

    async deleteTeam(team) {
        const response = await this.executeMethodDeleteItem(
            this.endpointDynamics + 'deleteGroup', team          
        );
        return response;
    }

    async addToTeam(team) {        
        const response = await this.executeMethodPostBody(
            this.endpointDynamics + 'assignUsers', team            
        );
        return response;
    } 


    async getDynamicsByStudent(offset, studentId, teamsIds, keyword, filters) {
        const queryParams = (`student?offset=${offset}&studentId=${studentId}&teamsIds=${teamsIds.toString()}${
            filters ? filters.replace(/[\r\n]+/g, "") : ""
        }${keyword ? `&keyword=${keyword}` : ""}`)
        const response = await this.executeMethodGetQuery(
            this.endpointDynamics,
            queryParams
        );
        return response.body;
    }

    async getStudentsByDynamic(dynamicId, keyword = null) {
        const response = await this.executeMethodGetQuery(
            `${this.endpointAlumnos}studentsByDynamic`,
            `?dynamicId=${dynamicId}${keyword ? `&keyword=${keyword}` : ""}`
        );
        return response.body;
    }

    async getDynamicById(dynamic_id) {
        const response = await this.executeMethodGetQuery(
            `${this.endpointDynamics}/dynamicById`,
            `?dynamic_id=${dynamic_id}`
        );
        return response.body;
    }

    async getAnsweredDynamicByStudent(dynamicId, userId) {
        const response = await this.executeMethodGetQuery(
            `${this.endpointDynamics}/answeredDynamicById`,
            `?dynamicId=${dynamicId}&userId=${userId}`
        );
        return response.body;
    }

    async addDynamic(dynamic: dynamic) {
        const response = await this.executeMethodPostQuery(
            this.endpointDynamics,
            dynamic
        );
        return response.body.dynamic_id;
    }

    async updateDynamic(dynamic: dynamic) {
        const response = await this.executeMethodPuttQuery(
            this.endpointDynamics,
            dynamic
        );
        return response;
    }

    async deleteDynamic(dynamic_id) {
        const response = await this.executeMethodDeleteQuery(
            this.endpointDynamics,
            `?dynamic_id=${dynamic_id}`
        );
        return response.body.dynamic_id;
    }

    async addPoll(poll) {
        const response = await this.executeMethodPostQuery(
            this.endpointPolls,
            poll
        );
        return response;
    }

    async updatePoll(poll) {
        const response = await this.executeMethodPuttQuery(
            this.endpointPolls,
            poll
        );
        console.log(response, 'respuesta update');
        return response; 
    }

    async deletePoll(poll_id) {
        const response = await this.executeMethodDeleteQuery(
            this.endpointPolls,
            `?poll_id=${poll_id}`
        );
        return response.body.poll_id;
    }

    async addVote(vote) {
        const response = await this.executeMethodPostQuery(
            this.endpointVotes,
            vote
        );
        return response;
    }

    async getVotesById(poll_id) {
        const { body } = await this.executeMethodGetQuery(
            `${this.endpointVotes}/votesById`,
            `?poll_id=${poll_id}`
        );
        return body;
    }

    async deleteVotes(poll_id) {
        const response = await this.executeMethodDeleteQuery(
            `${this.endpointVotes}/'votesById'`,
            `?poll_id=${poll_id}`
        );
        return response;
    }

    async getCatalogs(workspaceId, roleId: number, userId: number) {     
        userId = (roleId == UserRoles.ADMIN) ? 0 : userId; 
        const { body } = await this.executeMethodGetQuery(
            this.endpointCatalogs,
            `${workspaceId ? `?workspaceId=${workspaceId}&userId=${userId}` : ""}`
        );
        return body;
    }

    // Return the teams created by a particular Manager or return all the teams for a workspace in case of an Admin
    async getTeams(keyword: string = '', workspaceId: number, role_id: number, user_id: number) {
        const userId = (role_id == UserRoles.ADMIN) ? 0 : user_id;
        const queryParams = (`${keyword ? `?keyword=${keyword}` : ""}${workspaceId ? `${keyword ? '&':'?'}workspaceId=${workspaceId}` : ""}&userId=${userId}`)
        const { body } = await this.executeMethodGetQuery(
            `${this.endpointCatalogs}groupsSearch`,
            queryParams
            
        );
        return body;
    }

    async getTeamCount(workspaceId, roleId: number, userId: number) {  
        userId = (roleId == UserRoles.ADMIN ) ? 0 : userId;
        const queryParams = (`?workspaceId=${workspaceId}&userId=${userId}`);
        const { body } = await this.executeMethodGetQuery(
            `${this.endpointCatalogs}teamCount`,
            queryParams            
        );
        
        return body;
    }

    async cancelAccount(workspaceId: number, userId: number) {     
        const requestObject = {
            "workspaceId": workspaceId, 
            "userId": userId
        };       
        const response = await this.executeMethodPostBody(
            this.endpointLogin + 'cancelAccount', requestObject            
        );
        return response;
    }



    async (workspaceId: number, userId: number){

    }
}
